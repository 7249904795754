export const AUTH_APP = 'AUTH_APP';
export const ERROR_AXIOS = 'ERROR_AXIOS';
export const AUTH_APP_LOGOUT = 'AUTH_APP_LOGOUT';
export const APP_LOADING = 'APP_LOADING';
export const SM_LOADING = 'SM_LOADING';
export const GET_NIERUCHOMOSCI = 'GET_NIERUCHOMOSCI';
export const GET_NIERUCHOMOSC = 'GET_NIERUCHOMOSC';
export const ADD_NIERUCHOMOSC = 'ADD_NIERUCHOMOSC';
export const EDIT_NIERUCHOMOSC = 'EDIT_NIERUCHOMOSC';
export const DELETE_NIERUCHOMOSC = 'DELETE_NIERUCHOMOSC';
export const ERROR_NEW = 'ERROR_NEW';
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE';

export const GET_LOKATORZY = 'GET_LOKATORZY';
export const ADD_LOKATOR = 'ADD_LOKATOR';
export const DELETE_LOKATOR = 'DELETE_LOKATOR';
export const EDIT_LOKATOR = 'EDIT_LOKATOR';
export const GET_LOKATOR = 'GET_LOKATOR';
export const GET_LICZNIKI = 'GET_LICZNIKI';
export const ADD_LICZNIK = 'ADD_LICZNIK';
export const EDIT_LICZNIK = 'EDIT_LICZNIK';
export const DELETE_LICZNIK = 'DELETE_LICZNIK';

export const OD_LOADING = 'OD_LOADING';
export const GET_ODCZYTY = 'GET_ODCZYTY';
export const ADD_ODCZYT = 'ADD_ODCZYT';
export const EDIT_ODCZYT = 'EDIT_ODCZYT';
export const DELETE_ODCZYT = 'DELETE_ODCZYT';
