import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InputIcon from '@material-ui/icons/Input';

import PeopleIcon from '@material-ui/icons/People';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import {  SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 180,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, history, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Nieruchomości',
      href: '/nieruchomosci',
      icon: <DashboardIcon />
    },
    {
      title: 'Lokatorzy',
      href: '/lokatorzy',
      icon: <PeopleIcon />
    },
    {
      title: 'Rozliczenia',
      href: '/typography',
      icon: <AccountBalanceWalletIcon />
    },
    {
      title: 'Księgowość',
      href: '/cforders',
      icon: <AccountBalanceIcon />
    },
    {
      title: 'Powiadomienia',
      href: '/icons',
      icon: <NotificationsActiveIcon />
    },
    {
      title: 'Logout',
      href: '/logout',
      icon: <InputIcon />
    }
  ];


  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <SidebarNav
          history={history}
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
