import React from 'react';
import DataTable from './DataTable';

import { connect } from "react-redux";
import { getNieruchomosci, loadingFn, clearFn } from "../../../../actions/appActions";


class MainNieruchomosci extends React.Component {
    constructor() {
        super();
        this.handleRefresh = this.handleRefresh.bind(this);
    };
    
    async handleRefresh() {
        this.props.loadingFn(true);
        await this.props.getNieruchomosci();
    }

    async UNSAFE_componentWillMount() {
        this.props.clearFn();
        this.props.loadingFn(true);
        await this.props.getNieruchomosci();
    }

    render() {
        const { nieruchomosci } = this.props;
        const rows = nieruchomosci.map(row => ({ id: row.id, nazwa: row.nazwa, adres: row.adres, pow_uzyt: row.pow_uzyt, pow_komorki: row.pow_komorki, pow_parking: row.pow_parking, liczba_osob: row.liczba_osob, udzial: row.udzial, co: row.co, cw: row.cw, zw: row.zw, fr: row.fr, smieci: row.smieci, ekspluatacja: row.ekspluatacja}));
          
        const headCells = [
            { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
            { id: 'nazwa', numeric: false, disablePadding: true, label: 'Nazwa' },
            { id: 'adres', numeric: false, disablePadding: false, label: 'Adres' },
          ];

        return (
            <DataTable headCells={headCells} rows={rows} handleRefresh={this.handleRefresh}/>
        );
    }
};

const mapStateToProps = state => ({
    nieruchomosci: state.app_reducer.nieruchomosci
    });

export default connect( mapStateToProps,
    { getNieruchomosci, loadingFn, clearFn })(MainNieruchomosci);
