import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { connect } from "react-redux";
import { editLokator, loadingFn, getLokator, clearFn } from "../../actions/appActions";

import scrollIntoView from 'scroll-into-view-if-needed';

import clsx from 'clsx';
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Button,
    TextField
} from '@material-ui/core';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(4)
      },
    margin: {
        margin: theme.spacing(1),
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      color: "white",
      flex: 1,
    },
  });
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  class Edit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {},
            id: "",
            adres: "",
            nieruchomosc: {
                id: "",
                nazwa: ""
            },
            lokal: "",
            imie: "",
            nazwisko: "",
            pow_uzyt: "",
            pow_komorki: "",
            pow_parking: "",
            udzial: "",
            liczba_osob: "",
            email: "",
            tel: "",
            kom: "",
            uwagi: "",
            nr_bank: "",
            errors: {},
            loading: false
        }
    };

    
    async UNSAFE_componentWillMount(){

        if (this.props.open === true) {
            this.props.loadingFn(true);
           await this.props.getLokator(this.props.rowid)
           if (!this.props.error_axios) {
                return this.setState({
                    id:  this.props.lokator.id || "",
                    nieruchomosc: {
                        id: this.props.lokator.id_nieruchomosc || "", 
                        nazwa: this.props.lokator.nazwa || ""
                    },
                    lokal: this.props.lokator.lokal || "",
                    adres: this.props.lokator.adres || "",
                    imie: this.props.lokator.imie || "",
                    nazwisko: this.props.lokator.nazwisko || "",
                    pow_uzyt: this.props.lokator.pow_uzyt || "",
                    pow_komorki: this.props.lokator.pow_komorki || "",
                    pow_parking: this.props.lokator.pow_parking || "",
                    udzial: this.props.lokator.udzial || "",
                    liczba_osob: this.props.lokator.liczba_osob || "",
                    email: this.props.lokator.email || "",
                    tel: this.props.lokator.tel || "",
                    kom: this.props.lokator.kom || "",
                    uwagi: this.props.lokator.uwagi || "",
                    nr_bank: this.props.lokator.nr_bank || "",
                })
           }
        } 
    }

    handleChangeAuto = (e, value) => {
        e.persist();
        const { errors } = this.state;
        this.setState(
            (value === null)
            ? {
                nieruchomosc: {
                    id: "",
                    nazwa: ""
                },
                errors: {
                    ...errors,
                    nieruchomosc: true
                },
              }
            : {
                nieruchomosc: value,
                errors: {
                    ...errors,
                    nieruchomosc: false
                },
            }
        );
    }

    handleChange = (e) => {
        const { errors } = this.state;
        e.persist();
        this.setState(
            (e.target.value === "" && e.target.id === "lokal") ||
              (e.target.id === "lokal" && e.target.value.length > 255) 
            ? {
            
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: 'błędna wartość'
                },
              }
              : (e.target.value === "" && e.target.id === "imie") ||
            (e.target.id === "imie" && e.target.value.length < 3) ||
            (e.target.id === "imie" && e.target.value.length > 255) 
            ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
              }
            : (e.target.value === "" && e.target.id === "nazwisko") ||
            (e.target.id === "nazwisko" && e.target.value.length < 3) ||
            (e.target.id === "nazwisko" && e.target.value.length > 255) 
            ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
              }
              : (e.target.value === "" && e.target.id === "adres") ||
              (e.target.id === "adres" && e.target.value.length < 3) ||
              (e.target.id === "adres" && e.target.value.length > 255) 
              ? {
                  [e.target.id]: e.target.value,
                  errors: {
                      ...errors,
                      [e.target.id]: true
                  },
                }
              : (e.target.value === "" && e.target.id === "pow_uzyt") ||
            (e.target.id === "pow_uzyt" && e.target.value.length > 10) 
            ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
                }
            : (e.target.value === "" && e.target.id === "pow_komorki") ||
            (e.target.id === "pow_komorki" && e.target.value.length > 10) 
            ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
                }
            : (e.target.value === "" && e.target.id === "pow_parking") ||
            (e.target.id === "pow_parking" && e.target.value.length > 10) 
            ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
              }
            : (e.target.value === "" && e.target.id === "liczba_osob") ||
            (e.target.id === "liczba_osob" && !e.target.value.match(/^[0-9\b]+$/)) ||
            (e.target.id === "liczba_osob" && e.target.value.length > 10) 
            ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
            }
            : (e.target.value === "" && e.target.id === "udzial") ||
            (e.target.id === "udzial" && !e.target.value.match(/^[0-9\b]+$/)) ||
            (e.target.id === "udzial" && e.target.value.length > 3) 
            ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
              }
              : (e.target.id === "nr_bank" && !e.target.value.match(/^[0-9\b]+$/)) ||
              (e.target.id === "nr_bank" && e.target.value.length !== 26) 
              ? {
                  [e.target.id]: e.target.value,
                  errors: {
                      ...errors,
                      [e.target.id]: true
                  },
                }
            : {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: false
                },
            }
        );
    };

    handleSave = async (e) => {
        e.preventDefault();
        const {
            id,
            adres,
            nieruchomosc,
            lokal,
            imie,
            nazwisko,
            email,
            pow_uzyt,
            pow_komorki,
            pow_parking,
            udzial,
            liczba_osob,
            tel,
            kom,
            uwagi,
            nr_bank,
            errors
          } = this.state;
        if (nieruchomosc.nazwa === "" || Object.keys(nieruchomosc).length === undefined) {
            this.setState({ 
                errors: { ...errors, nieruchomosc: 'błędna wartość' }
            });
            const node = document.getElementById('nieruchomosc');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        if (lokal === "" || lokal.length > 255) {
            this.setState({ 
                errors: { ...errors, lokal: 'błędna wartość' }
            });
            const node = document.getElementById('lokal');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        if (pow_uzyt === "" || pow_uzyt.length > 10) {
            this.setState({ 
                errors: { ...errors, pow_uzyt: true }
            });
            const node = document.getElementById('pow_uzyt');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        if (pow_komorki === "" || pow_komorki.length > 10) {
            this.setState({ 
                errors: { ...errors, pow_komorki: true }
            });
            const node = document.getElementById('pow_komorki');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        if ( pow_parking === "" || pow_parking.length > 10) {
            this.setState({ 
                errors: { ...errors,  pow_parking: true }
            });
            const node = document.getElementById('pow_parking');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        if ( liczba_osob === "" || liczba_osob.length > 10) {
            this.setState({ 
                errors: { ...errors,  liczba_osob: true }
            });
            const node = document.getElementById('liczba_osob');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        }
        if ( udzial === "" || udzial.length > 10) {
            this.setState({ 
                errors: { ...errors,  udzial: true }
            });
            const node = document.getElementById('udzial');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        }
        if (imie === "" || imie.length < 3 || imie.length > 255) {
            this.setState({ 
                errors: { ...errors, imie: true }
            });
            const node = document.getElementById('imie');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        if (nazwisko === "" || nazwisko.length < 3 || nazwisko.length > 255) {
            this.setState({ 
                errors: { ...errors, nazwisko: true }
            });
            const node = document.getElementById('nazwisko');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        if (adres === "" || adres.length < 3 || adres.length > 255) {
            this.setState({ 
                errors: { ...errors, adres: true }
            });
            const node = document.getElementById('adres');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        await this.setState({
            values: {
                id: id,
                adres: adres,
                id_nieruchomosc: nieruchomosc.id,
                lokal: lokal,
                nazwisko: nazwisko,
                imie: imie,
                email: email,
                pow_uzyt: pow_uzyt,
                pow_komorki: pow_komorki,
                pow_parking: pow_parking,
                udzial: udzial,
                liczba_osob: liczba_osob,
                tel: tel,
                kom: kom,
                uwagi: uwagi,
                nr_bank
            },
            loading: true 
        });
        
        this.props.clearFn();
       this.props.loadingFn(true);
        await this.props.editLokator(this.state.values);
        if (this.props.error_axios) {  
            this.setState({
                loading: false 
            });
            return;
        } 
        this.setState({
            isValid: false,
            loading: false 
        });
        this.props.handleClose();
    }
    render() {
        const { rowid, classes, editLokator, getLokator, loadingFn, clearFn, handleClose, error_axios, success_axios, open, className, light_nieruchomosci, ...rest } = this.props;
        const {  loading, errors, lokal, adres, nazwisko, imie, pow_uzyt, pow_komorki, email, pow_parking, udzial, liczba_osob, tel, kom, uwagi, nr_bank, nieruchomosc } = this.state

        return (
            <div>
            <Dialog fullScreen open={open} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" disabled={loading ? true : false}>
                    <CloseIcon />
                    </IconButton>
                    <Typography variant="h3" className={classes.title}>
                    Edytuj Lokatora
                    </Typography>
                    <Button autoFocus color="primary" style={{ backgroundColor: "aliceblue" }} variant="outlined" onClick={this.handleSave} disabled={loading ? true : false}>
                    Zapisz
                    </Button>
                </Toolbar>
                </AppBar>
                <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                item
                md={6}
                xs={12}
                >
                    <Card
                        {...rest}
                        className={clsx(className)}
                    >
                    <form>
                        <CardHeader
                        title="Dane Lokalu"
                        />
                        <Divider />
                        <CardContent>
                        <Autocomplete
                            id="nieruchomosc"
                            autoSelect
                            value={nieruchomosc}
                            onChange={this.handleChangeAuto}
                            options={light_nieruchomosci}
                            getOptionLabel={option => option.nazwa}
                            renderInput={params => <TextField {...params} 
                            required  
                            error={errors.nieruchomosc ? true : false}
                            helperText={
                                errors.nieruchomosc ? 'błędna wartość'  : null
                            } 
                            label="Nieruchomość" 
                            variant="outlined" />}
                            disabled={ loading ? true : false}
                            />
                        <TextField
                            required
                            fullWidth
                            label="lokal"
                            name="lokal"
                            id="lokal"
                            style={{ marginTop: '1rem' }}
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            type="text"
                            value={lokal}
                            variant="outlined"
                            error={errors.lokal ? true : false}
                            helperText={
                                errors.lokal ?  'błędna wartość'  : null
                            }
                            disabled={ loading ? true : false}
                        />
                         <TextField
                            required
                            fullWidth
                            label="Powierzchnia użytkowa m2"
                            name="pow_uzyt"
                            id="pow_uzyt"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="number"
                            value={pow_uzyt}
                            variant="outlined"
                            error={errors.pow_uzyt ? true : false  }
                            helperText={
                                errors.pow_uzyt ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Powierzchnia komórki m2"
                            name="pow_komorki"
                            id="pow_komorki"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="number"
                            value={pow_komorki}
                            variant="outlined"
                            error={errors.pow_komorki ? true : false  }
                            helperText={
                                errors.pow_komorki ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Powierzchnia Parkingowa m2"
                            name="pow_parking"
                            id="pow_parking"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="number"
                            value={pow_parking}
                            variant="outlined"
                            error={errors.pow_parking ? true : false  }
                            helperText={
                                errors.pow_parking ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Liczba osób"
                            name="liczba_osob"
                            id="liczba_osob"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="number"
                            value={liczba_osob}
                            variant="outlined"
                            error={errors.liczba_osob ? true : false  }
                            helperText={
                                errors.liczba_osob ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Udział %"
                            name="udzial"
                            id="udzial"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="number"
                            value={udzial}
                            variant="outlined"
                            error={errors.udzial ? true : false  }
                            helperText={
                                errors.udzial ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                        </CardContent>
                    </form>
                    </Card>
                </Grid>
                <Grid
                item
                md={6}
                xs={12}
                >
                <Card
                {...rest}
                className={clsx(className)}
                >
                <form>
                    <CardHeader
                    title="Dodatkowe Kontaktowe"
                    />
                    <Divider />
                    <CardContent>
                    <TextField
                            required
                            fullWidth
                            label="imię"
                            name="imie"
                            id="imie"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            type="text"
                            value={imie}
                            variant="outlined"
                            error={errors.imie ? true : false  }
                            helperText={
                                errors.imie ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                        <TextField
                            required
                            fullWidth
                            label="nazwisko"
                            name="nazwisko"
                            id="nazwisko"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="text"
                            value={nazwisko}
                            variant="outlined"
                            error={errors.nazwisko ? true : false  }
                            helperText={
                                errors.nazwisko ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                         <TextField
                            required
                            fullWidth
                            label="adres"
                            name="adres"
                            id="adres"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="text"
                            value={adres}
                            variant="outlined"
                            error={errors.adres ? true : false  }
                            helperText={
                                errors.adres ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        id="email"
                        onChange={this.handleChange}
                        onFocus={this.handleChange}
                        onBlur={this.handleChange}
                        style={{ marginTop: '1rem' }}
                        type="email"
                        value={email}
                        variant="outlined"
                        error={errors.email ? true : false  }
                        helperText={
                            errors.email ? 'błędna wartość' : null
                        }
                        disabled={ loading ? true : false}
                    />
                    <TextField
                        fullWidth
                        label="Telefon"
                        name="tel"
                        id="tel"
                        onChange={this.handleChange}
                        onFocus={this.handleChange}
                        onBlur={this.handleChange}
                        style={{ marginTop: '1rem' }}
                        type="text"
                        value={tel}
                        variant="outlined"
                        error={errors.tel ? true : false  }
                        helperText={
                            errors.tel ? 'błędna wartość' : null
                        }
                        disabled={ loading ? true : false}
                    />
                    <TextField
                        fullWidth
                        label="Telefon Kom."
                        name="kom"
                        id="kom"
                        onChange={this.handleChange}
                        onFocus={this.handleChange}
                        onBlur={this.handleChange}
                        style={{ marginTop: '1rem' }}
                        type="text"
                        value={kom}
                        variant="outlined"
                        error={errors.kom ? true : false  }
                        helperText={
                            errors.kom ? 'błędna wartość' : null
                        }
                        disabled={ loading ? true : false}
                    />
                    <TextField
                        fullWidth
                        label="uwagi"
                        name="uwagi"
                        id="uwagi"
                        onChange={this.handleChange}
                        onFocus={this.handleChange}
                        onBlur={this.handleChange}
                        style={{ marginTop: '1rem' }}
                        type="text"
                        value={uwagi}
                        variant="outlined"
                        error={errors.uwagi ? true : false  }
                        helperText={
                            errors.uwagi ? 'błędna wartość' : null
                        }
                        disabled={ loading ? true : false}
                    />
                    </CardContent>
                </form>
                </Card>
                <Card
                {...rest}
                className={clsx(className)}
                >
                <form>
                    <CardHeader
                    title="Konto Bankowe"
                    />
                    <Divider />
                    <CardContent>
                    <TextField
                        fullWidth
                        label="Nr. Konta"
                        name="nr_bank"
                        id="nr_bank"
                        onChange={this.handleChange}
                        onFocus={this.handleChange}
                        onBlur={this.handleChange}
                        type="number"
                        value={nr_bank}
                        variant="outlined"
                        error={errors.nr_bank ? true : false  }
                        helperText={
                            errors.nr_bank ? 'błędna wartość' : null
                        }
                        disabled={ loading ? true : false}
                    />
                    </CardContent>
                </form>
                </Card>
                </Grid>
            </Grid>
            </div>
            
            </Dialog>
            </div>
        );
    }
};

const mapStateToProps = state => ({
    lokator: state.app_reducer.lokator,
    error_axios: state.app_reducer.error_axios,
    light_nieruchomosci: state.app_reducer.light_nieruchomosci
  });

export default withStyles(useStyles)(connect(
    mapStateToProps,
{ editLokator, loadingFn, getLokator, clearFn }
)(Edit));