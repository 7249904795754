import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';


import { connect } from "react-redux";
import { deleteNieruchomosc, loadingFn, clearFn } from "../../../../actions/appActions";

const useStyles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "white",
    },
  });

  const DialogTitle = withStyles(useStyles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

class Delete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    };


    handleSave = async () => {
        this.setState({
            loading: true 
        });
        this.props.clearFn();
        this.props.loadingFn(true);
        await this.props.deleteNieruchomosc(this.props.rowselected.id);
        this.setState({
            loading: false 
        });
        this.props.handleClose();
    }
    
  render () {
    const { rowselected, handleClose, open } = this.props;
    const { loading } = this.state;

    return (
        <div>
           <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{backgroundColor: "#f44336"}}>
           <b style={{color: "white"}}>Usuń {rowselected.nazwa}</b>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <b>Uwaga !!!</b>
          </Typography>
          <Typography gutterBottom>
           Chcesz usunąć nieruchomość {rowselected.nazwa}.
          </Typography>
          <Typography gutterBottom>
            Bądź świadom, że spowoduje to usunięcie wszsytkich danych nieruchomości łącznie z rozliczeniami i lokatorami.
          </Typography>
        </DialogContent>
        <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary" disabled={loading ? true : false}>
            Anuluj
          </Button>
          <Button autoFocus onClick={this.handleSave} color="secondary" disabled={loading ? true : false}>
            Usuń
          </Button>
        </DialogActions>
      </Dialog>
        </div>
      );
  }
}

const mapStateToProps = state => ({
    error_axios: state.app_reducer.error_axios
  });

export default withStyles(useStyles)(connect(
    mapStateToProps,
{ deleteNieruchomosc, loadingFn, clearFn }
)(Delete));