import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import {
  MainNieruchomosci
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: "32px",
    paddingRight: "2px",
    paddingLeft: "21px",
    [theme.breakpoints.down('md')]: {
      paddingLeft: "2px",
    }
  }
}));

const Nieruchomosci = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
    
    <div className={classes.root}>
    <Typography variant="h4" style={{paddingLeft: "10px", paddingBottom: "10px"}}>Nieruchomości</Typography>
        <MainNieruchomosci />
    </div>
   </React.Fragment>
  );
};

export default Nieruchomosci;
