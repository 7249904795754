import {
  ERROR_AXIOS,
  AUTH_APP,
  AUTH_APP_LOGOUT,
  APP_LOADING,
  SM_LOADING,
  GET_NIERUCHOMOSCI,
  GET_NIERUCHOMOSC,
  ADD_NIERUCHOMOSC,
  EDIT_NIERUCHOMOSC,
  DELETE_NIERUCHOMOSC,
  CLEAR_RESPONSE,
  GET_LOKATORZY,
  ADD_LOKATOR,
  DELETE_LOKATOR,
  EDIT_LOKATOR,
  GET_LOKATOR,
  GET_LICZNIKI,
  ADD_LICZNIK,
  EDIT_LICZNIK,
  DELETE_LICZNIK,
  OD_LOADING,
  GET_ODCZYTY,
  ADD_ODCZYT,
  EDIT_ODCZYT,
  DELETE_ODCZYT
} from "./types";
import axios from "axios";

//app functions
export function login(data) {
  const content = {
      email: data.email,
      password: data.password
    };
  return dispatch => {
    return axios
      .post('https://api.in4system.com/api/login', content)
      .then(res => {
        localStorage.setItem('jwtToken', res.data.token);
        dispatch({
          type: AUTH_APP
        });
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
  }
}
export function logout() {
  const content = {
    token: localStorage.getItem('jwtToken'),
  };
  const header = {
    Authorization: "Bearer " + content.token
  };
    return dispatch => {
        return axios.post('https://api.in4system.com/api/jfg5345hfgh4545b4234234gv', content.token? content.token : null, content.token? { headers: header } : null)
            .then(res => {  localStorage.removeItem('jwtToken', content.token); 
              dispatch({
                type: AUTH_APP_LOGOUT
              });
            })
            .catch(e => {
              localStorage.removeItem('jwtToken');
              dispatch({
                type: AUTH_APP_LOGOUT
              })}
            );
  }
}
export const checkAuth = (token) => dispatch => {
  const header = {
      Authorization: "Bearer " + token
    };
    axios.post('https://api.in4system.com/api/checkauth', "", { headers: header })
        .then(  res => { 
          dispatch({
            type: AUTH_APP
          }); 
        })
        .catch(e => {
          if (e.response) {
            if (e.response.status === 404) {
                return dispatch({
                type: ERROR_AXIOS,
                payload: e.message
              });
            }
            if (e.response.status === 401){
              return dispatch({
                type: ERROR_AXIOS,
                payload: e.response.message
              });
            }
            return dispatch({
              type: ERROR_AXIOS,
              payload: e.response.data
            });
          } else {
            return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
        });
}
export function loadingFn(loading) {
  return dispatch => {
    dispatch({
      type: APP_LOADING,
      payload: loading
    })
  };
} 
export function loadingFnOd(loading) {
  return dispatch => {
    dispatch({
      type: OD_LOADING,
      payload: loading
    })
  };
} 
export function loadingFnSm(loading) {
  return dispatch => {
    dispatch({
      type: SM_LOADING,
      payload: loading
    })
  };
} 
export function clearFn() {
  return dispatch => {
    dispatch({
      type: CLEAR_RESPONSE
    })
  };
} 
// Nieruchomosci
export const addNieruchomosc = (data) => async dispatch => {
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/add_nieruchomosc', data, { headers: header })
      .then( res => { 
        return dispatch({
          type: ADD_NIERUCHOMOSC,
          payload: res.data
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          if (e.response.status === 401){
            return dispatch({
              type: ERROR_AXIOS,
              payload: e.response.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}
export const deleteNieruchomosc = (id) => async dispatch => {
  const content = {
    id: id
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/delete_nieruchomosc', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: DELETE_NIERUCHOMOSC,
          payload: res.data
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}
export const editNieruchomosc = (data) => async dispatch => {
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/edit_nieruchomosc', data, { headers: header })
      .then(  res => { 
        return dispatch({
          type: EDIT_NIERUCHOMOSC,
          payload: res.data
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}
export const getNieruchomosc = (id) => async dispatch => {
  const content = {
    id: id
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/get_nieruchomosc', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: GET_NIERUCHOMOSC,
          payload: res
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}
export const getNieruchomosci = () => async dispatch => {
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/get_nieruchomosci', "", { headers: header })
      .then(  res => { 
        return dispatch({
          type: GET_NIERUCHOMOSCI,
          payload: res
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}

//Lokatorzy
export const addLokator = (data) => async dispatch => {
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/add_lokator', data, { headers: header })
      .then( res => { 
        return dispatch({
          type: ADD_LOKATOR,
          payload: res.data
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}
export const deleteLokator = (id) => async dispatch => {
  const content = {
    id: id
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/delete_lokator', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: DELETE_LOKATOR,
          payload: res.data
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}
export const editLokator = (data) => async dispatch => {
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/edit_lokator', data, { headers: header })
      .then(  res => { 
        return dispatch({
          type: EDIT_LOKATOR,
          payload: res.data
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}
export const getLokator = (id) => async dispatch => {
  const content = {
    id: id
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/get_lokator', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: GET_LOKATOR,
          payload: res
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}
export const getLokatorzy = (id) => async dispatch => {
  const content = {
    id: id
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/get_lokatorzy', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: GET_LOKATORZY,
          payload: res
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}

//Liczniki
export const getLiczniki = (id) => async dispatch => {
  const content = {
    id_lokal: id
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/get_liczniki', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: GET_LICZNIKI,
          payload: res
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}
export const addLicznik = (nazwa, numer, id_lokal, prognoza) => async dispatch => {
  const content = {
    nazwa: nazwa,
    numer: numer,
    id_lokal: id_lokal,
    prognoza: prognoza
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/add_licznik', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: ADD_LICZNIK,
          payload: res.data
        }); 
      })
      .catch(e => {
        
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}

export const editLicznik = (nazwa, numer, id, prognoza) => async dispatch => {
  const content = {
    id: id,
    nazwa: nazwa,
    numer: numer,
    prognoza: prognoza
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/edit_licznik', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: EDIT_LICZNIK,
          payload: res.data
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}
export const deleteLicznik = (id) => async dispatch => {
  const content = {
    id: id
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/delete_licznik', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: DELETE_LICZNIK,
          payload: res.data
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}

//ODCZYTY
export const getOdczyty = (id, date) => async dispatch => {
  const content = {
    id_licznik: id,
    data: date
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/get_odczyty', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: GET_ODCZYTY,
          payload: res
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}
export const addOdczyt = (id_licznik, odczyt, odczytany, prognoza) => async dispatch => {
  const content = {
    id_licznik: id_licznik,
    odczyt: odczyt,
    odczytany: odczytany,
    prognoza: prognoza
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/add_odczyt', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: ADD_ODCZYT,
          payload: res.data
        }); 
      })
      .catch(e => {
        
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}

export const editOdczyt = (id, odczyt, odczytany, prognoza) => async dispatch => {
  const content = {
    id: id,
    odczyt: odczyt,
    odczytany: odczytany,
    prognoza: prognoza
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/edit_odczyt', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: EDIT_ODCZYT,
          payload: res.data
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}
export const deleteOdczyt = (id) => async dispatch => {
  const content = {
    id: id
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/delete_odczyt', content, { headers: header })
      .then(  res => { 
        return dispatch({
          type: DELETE_ODCZYT,
          payload: res.data
        }); 
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 404) {
              return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data.message
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
}

export const checkNazwa = (data) => async dispatch => {
  const content = {
    nazwa: data
  }
  const header = {
    Authorization: "Bearer " + localStorage.getItem('jwtToken')
  };
  await axios.post('https://api.in4system.com/api/check_nazwa', content, { headers: header })
      .then( res => { 
        return res;
      })
      .catch(e => {
        if (e.response) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        } 
      });
}


