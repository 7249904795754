import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CircularProgress from "@material-ui/core/CircularProgress";
import { lighten, makeStyles, fade } from '@material-ui/core/styles';
import { Card, TextField,  Grid, CardHeader } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import InputBase from '@material-ui/core/InputBase';
import AddBox from '@material-ui/icons/AddBox';
import DeleteDialog from './DeleteLiczniki';

import Odczyty from './Odczyty';

import { connect } from "react-redux";
import { getLiczniki, loadingFnSm, clearFn, addLicznik, editLicznik } from "../../actions/appActions";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const { headCells, classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='default' style={{width: "50px", maxWidth: "50px", minWidth: "50px"}}></TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: "48px"
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  search: {
    backgroundColor: "aliceblue",
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'black',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { selectedRow, numSelected, handleSearch, handleNewOpen, handleEditOpen, handleDeleteOpen, searchval, selectedLokator } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
         {selectedRow.nazwa} / {selectedRow.nr}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
        </Typography>
      )}

      {numSelected > 0 ? (
        <React.Fragment>
        <Tooltip title={"Edytuj"}>
            <IconButton aria-label="Edytuj" onClick={handleEditOpen}> 
            <Edit />
            </IconButton>
        </Tooltip>
        <Tooltip title={"Usuń"}>
            <IconButton  aria-label="Usuń" onClick={handleDeleteOpen}>
            <DeleteOutline />
            </IconButton>
        </Tooltip>
      </React.Fragment>
      ) : selectedLokator ? (
        <React.Fragment>
        <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Znajdź…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={searchval} 
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearch}
        />
      </div>
        <Tooltip title="Dodaj Licznik">
          <IconButton aria-label="Dodaj" onClick={handleNewOpen}>
            <AddBox />
          </IconButton>
        </Tooltip>
        </React.Fragment>
      ) : (
        <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Znajdź…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={searchval} 
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearch}
        />
      </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const  headCells = [
  { id: 'nazwa', numeric: false, disablePadding: true, label: 'Nazwa' },
  { id: 'nr', numeric: false, disablePadding: true, label: 'Numer' },
  { id: 'prognoza', numeric: false, disablePadding: true, label: 'Prognoza m3' },
];


function Liczniki(props) {
    const {  id_lokal, lokal, nazwa, liczniki, getLiczniki,  loading_sm, loadingFnSm, clearFn, addLicznik, editLicznik } = props;
   const [rows, setRows] = React.useState([]);
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('nazwa');
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [search, setSearch] = React.useState([]);
  const [searchval, setSearchval] = React.useState({search: ""});

  const [newOpen, setnewOpen] = React.useState(false);
  const [editOpen, seteditOpen] = React.useState({id: ''});
  const [editClicked, seteditClicked] = React.useState(false);
  const [deleteOpen, setdeleteOpen] = React.useState(false);

  const [formState, setFormState] = React.useState({nazwa: "", numer: "", prognoza: "", errors: {}});


  const [formStateEdit, setFormStateEdit] = React.useState({nazwa: "", numer: "", prognoza: "", errors: {}});

  

  const handleDeleteOpen = () => {
      setdeleteOpen(true);
  }
  const handleDeleteClose = async () => {
    setdeleteOpen(false);
    loadingFnSm(true);
    setSelected([]);
    await getLiczniki(id_lokal);
    
  }

  const handleNewOpen = () => {
      setnewOpen(true);
  }
  const handleNewClose = async () => {
    setnewOpen(false);
    setFormState( formState => ({...formState, nazwa: "", numer: "", prognoza: ""}))
  }

  const handleAddLicznik = async () => {
    
    if (formState.nazwa === "" || formState.nazwa.length < 2) {
      setFormState( formState => ({...formState, errors: {nazwa: 'błędna wartość' } }))
      return;
    } 
    if (formState.prognoza !== "" && !formState.prognoza.match(/^[1-9\b]+$/)) {
      setFormState( formState => ({...formState, errors: {prognoza: 'błędna wartość' } }))
      return;
    } 
    setnewOpen(false)
    loadingFnSm(true)
    clearFn();
    await addLicznik(formState.nazwa, formState.numer, id_lokal, formState.prognoza);
    getLiczniki(id_lokal);
    setFormState( formState => ({...formState, nazwa: "", numer: "", errors: {nazwa: '' , numer: '', prognoza: ''} }))
  }

  const handleEditOpen = () => {
    seteditClicked(true);
  }
  const handleEditClose = async () => {
    seteditClicked(false);
    seteditOpen({id: ''});
  }

  const handleEditLicznik = async () => {
    if (formStateEdit.nazwa === "" || formStateEdit.nazwa.length < 2) {
      setFormState( formState => ({...formState, errors: {nazwa: 'błędna wartość' } }))
      return;
    } 
    if (formStateEdit.prognoza !== ""  && !formStateEdit.prognoza.match(/^[1-9\b]+$/)) {
      setFormState( formState => ({...formState, errors: {prognoza: 'błędna wartość' } }))
      return;
    } 
    seteditClicked(false);
    seteditOpen({id: ''});
    loadingFnSm(true)
    clearFn();
    await editLicznik(formStateEdit.nazwa, formStateEdit.numer, editOpen.id, formStateEdit.prognoza);
    getLiczniki(id_lokal);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.id);
    if (selectedIndex === -1) {
        setSelected([row.id]);
        setSelectedRow(row);
    } else {
      setSelected([]);
      setSelectedRow({});
    }
  };
  const handleSearch = (event) => {
    event.persist();
    setSearchval(searchval => ({...searchval, search: event.target.value}));
  };

  const handleChange = (e) => {
    e.persist()
      setFormState( formState => ({
        ...formState, [e.target.id]: e.target.value, 
        errors: 
          (e.target.id === 'nazwa' && e.target.value.length < 2) ? { nazwa: 'błędna wartość' } 
          : (e.target.id === 'prognoza' && !e.target.value.match(/^[1-9\b]+$/) && e.target.value !== "") ? { prognoza: 'błędna wartość' } 
          : {}
      })
      )
  }

  const handleChangeEdit = (e) => {
    e.persist()
      setFormStateEdit( formStateEdit => ({
        ...formStateEdit, [e.target.id]: e.target.value, 
        errors: 
        (e.target.id === 'nazwa' && e.target.value.length < 2) ? { nazwa: 'błędna wartość' } 
        : (e.target.id === 'prognoza' && !e.target.value.match(/^[1-9\b]+$/) && e.target.value !== "") ? { prognoza: 'błędna wartość' } 
        : {}
      })
      )
  }

  useEffect(() => {
    if (selected.length !== 0) {
      const ret = rows.filter(r => r.id === selected[0]);
      setSelectedRow(ret[0]);
      if (editClicked) {
        seteditOpen({id: selectedRow.id});
        setFormStateEdit(formStateEdit => ({...formStateEdit, nazwa: ret[0].nazwa, numer: ret[0].nr === null ? "" : ret[0].nr, prognoza: ret[0].prognoza === null ? "" : ret[0].prognoza}))
        seteditOpen({id: ret[0].id});
      }
    } else {
      if (!editClicked) {
        seteditClicked(false);
        seteditOpen({id: ''});
        setFormStateEdit(formStateEdit => ({...formStateEdit, nazwa: '', numer: '', prognoza: ''}))
        seteditOpen({id: ''});
      }
      setSelectedRow([]);
    }
    if (searchval.search === "") {
      setSearch(rows);
  } else {
      let rest = [];
      rows.forEach(function(row){
        return Object.keys(row).some(function(key){
            if(row[key] !== null){
              if (row[key].toString().toLowerCase().includes(searchval.search.toLowerCase())) {
                return rest.push(row);
              }
            }
            return false;
          })
      })
      if(rest.length === 0 ){
        setSearch([]);
      } else {
        setSearch(rest);
      }
  } 
  }, [rows, selected, editClicked, searchval, selectedRow.id]);
  
  useEffect(() => {

      if (id_lokal !== undefined) {
        seteditClicked(false);
        seteditOpen({id: ''});
        setSelected([]);
        clearFn();
        loadingFnSm(true)
           getLiczniki(id_lokal);
      } else {
        setSelected([]);
        setnewOpen(false)
        seteditClicked(false);
        seteditOpen({id: ''});
        setRows([]);
        return
      }
  }, [id_lokal, getLiczniki, clearFn, loadingFnSm])

  useEffect(() => {
    if (liczniki.length === 0 ) {
      seteditClicked(false);
      seteditOpen({id: ''});
      setnewOpen(false)
      setSelected([]);
      return  setRows([]);
    } else {
      return setRows(liczniki)
    }
}, [liczniki])


  const isSelected = id => selected.indexOf(id) !== -1;

  //const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <React.Fragment>
    <Grid
    item
    lg={4}
    md={4}
    xl={4}
    xs={12}
    >
      <Card>
        <CardHeader title={lokal === undefined? "Liczniki": "Liczniki "+lokal+" / "+nazwa}>
        </CardHeader>
        <Card>
          <div className={classes.root}>
              {deleteOpen ? <DeleteDialog open={deleteOpen} handleClose={handleDeleteClose} rowselected={selectedRow}/> : null}
              <EnhancedTableToolbar 
                  selectedRow={selectedRow}
                  numSelected={selected.length}
                  handleSearch={handleSearch}
                  handleNewOpen={handleNewOpen}
                  handleEditOpen={handleEditOpen}
                  handleDeleteOpen={handleDeleteOpen}
                  selectedLokator={id_lokal !== undefined? true: false}
                  searchval={searchval.search}
              />
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={'small'}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                  {newOpen?
                      <TableRow>
                      <React.Fragment>
                      <TableCell align="left"  style={{width: "96px", padding: "0"}}>
                      <Tooltip title={"Zapisz"} onClick={handleAddLicznik}>
                        <IconButton aria-label="Zapisz"> 
                          <CheckIcon  />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"Anuluj"}>
                        <IconButton aria-label="Anuluj" onClick={handleNewClose}> 
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                      </TableCell>
                      <TableCell  align="left">
                      <TextField
                          required
                            fullWidth
                            label="nazwa"
                            name="nazwa"
                            id="nazwa"
                            onChange={handleChange}
                            onFocus={handleChange}
                            onBlur={handleChange}
                            type="text"
                            value={formState.nazwa}
                            error={formState.errors.nazwa ? true : false  }
                            helperText={
                              formState.errors.nazwa ? 'błędna wartość' : null
                            }
                        />

                      </TableCell>
                       <TableCell align="left" >

                       <TextField
                            fullWidth
                            label="numer"
                            name="numer"
                            id="numer"
                            onChange={handleChange}
                            type="text"
                            value={formState.numer}
                        />
                       </TableCell>
                       <TableCell align="left" >
                        <TextField
                            fullWidth
                            label="prognoza"
                            name="prognoza"
                            id="prognoza"
                            onChange={handleChange}
                            type="text"
                            value={formState.prognoza}
                            error={formState.errors.prognoza ? true : false  }
                            helperText={
                              formState.errors.prognoza ? 'błędna wartość'  : null
                            }
                        />
                        </TableCell>
                      </React.Fragment>
                    </TableRow> 
                  : null}
                  {search.length !== 0 && !loading_sm ? ( 
                      stableSort( search, getComparator(order, orderBy))
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <React.Fragment key={row.id}>
                            {editOpen.id === row.id?
                            <React.Fragment>
                            <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          >
                            <TableCell align="left"  style={{width: "96px", padding: "0"}}>
                            <Tooltip title={"Zapisz"} onClick={handleEditLicznik}>
                              <IconButton aria-label="Zapisz"> 
                                <CheckIcon  />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"Anuluj"}>
                              <IconButton aria-label="Anuluj" onClick={handleEditClose}> 
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                            </TableCell>
                            <TableCell  align="left">
                            <TextField
                                required
                                  fullWidth
                                  label="nazwa"
                                  name="nazwa"
                                  id="nazwa"
                                  onChange={handleChangeEdit}
                                  onFocus={handleChangeEdit}
                                  onBlur={handleChangeEdit}
                                  type="text"
                                  value={formStateEdit.nazwa}
                                  error={formStateEdit.errors.nazwa ? true : false  }
                                  helperText={
                                    formStateEdit.errors.nazwa ? 'błędna wartość' : null
                                  }
                              />
                            </TableCell>
                             <TableCell align="left" >
                             <TextField
                                  fullWidth
                                  label="numer"
                                  name="numer"
                                  id="numer"
                                  onChange={handleChangeEdit}
                                  onFocus={handleChangeEdit}
                                  onBlur={handleChangeEdit}
                                  type="text"
                                  value={formStateEdit.numer}
                              />
                             </TableCell>
                             <TableCell  align="left">
                            <TextField
                                  fullWidth
                                  label="prognoza"
                                  name="prognoza"
                                  id="prognoza"
                                  onChange={handleChangeEdit}
                                  onFocus={handleChangeEdit}
                                  onBlur={handleChangeEdit}
                                  type="text"
                                  value={formStateEdit.prognoza}
                                  error={formStateEdit.errors.prognoza ? true : false  }
                                  helperText={
                                    formStateEdit.errors.prognoza ? 'błędna wartość' : null
                                  }
                              />
                            </TableCell>
                             </TableRow>
                            </React.Fragment>
                            : 
                              <React.Fragment>
                              <TableRow
                              hover
                              onClick={event => handleClick(event, row)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                                 <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </TableCell>
                                
                                <TableCell  id={labelId} scope="row" align="left">{row.nazwa}</TableCell>
                                <TableCell align="left" >{row.nr}</TableCell>
                                <TableCell align="left" >{row.prognoza}</TableCell>
                                </TableRow>
                              </React.Fragment>
                            }
                           </React.Fragment>
                          
                        );
                      })
                      ) : (!newOpen) ?
                      <TableRow>
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell style={{verticalAlign: "middle", left: "35%", position: "sticky", height: "70px"}}>{loading_sm ? (<CircularProgress size={40}/>) : "Brak danych" }</TableCell>
                      </TableRow>
                    :  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell style={{verticalAlign: "middle", left: "35%", position: "sticky", height: "70px"}}>{loading_sm ? (<CircularProgress size={40}/>) : "Brak danych" }</TableCell>
                  </TableRow>
                  }
                  </TableBody>
                </Table>
              </TableContainer>
          </div>
        </Card>
        </Card>
      </Grid>
       <Grid
       item
       lg={1}
       md={1}
       xl={1}
       xs={12}
       />
       <Grid
       item
       lg={3}
       md={3}
       xl={3}
       xs={12}
       />
       <Grid
       item
       lg={6}
       md={6}
       xl={6}
       xs={12}
       >
        <Card>
          <CardHeader title={selectedRow.nazwa === undefined? "Odczyty": "Odczyty "+selectedRow.nazwa+" / "+selectedRow.nr}>
          </CardHeader>
          <Odczyty id_licznik={selectedRow.id} prognoza={selectedRow.prognoza}/>
        </Card>
       </Grid>
      </React.Fragment>
  );
}

const mapStateToProps = state => ({
  liczniki: state.app_reducer.liczniki,
  loading_sm: state.app_reducer.loading_sm,
  });

export default connect( mapStateToProps,
  { getLiczniki, loadingFnSm, clearFn, addLicznik, editLicznik})(Liczniki);