import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CircularProgress from "@material-ui/core/CircularProgress";
import { lighten, makeStyles, fade } from '@material-ui/core/styles';
import { Card, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import InputBase from '@material-ui/core/InputBase';
import AddBox from '@material-ui/icons/AddBox';
import DeleteDialog from './DeleteOdczyt';

import DateFnsUtils from '@date-io/date-fns';
import plLocale from "date-fns/locale/pl";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';


import { connect } from "react-redux";
import { getOdczyty, loadingFnOd, clearFn, addOdczyt, editOdczyt } from "../../actions/appActions";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const { headCells, classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='default' style={{width: "50px", maxWidth: "50px", minWidth: "50px"}}></TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: "48px",
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  search: {
    backgroundColor: "aliceblue",
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'black',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, handleSearch, handleNewOpen, handleEditOpen, handleDeleteOpen, searchval, selectedLicznik, selectedDate, handleDateChange } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
        </Typography>
      )}

      {numSelected > 0 ? (
        <React.Fragment>
        <Tooltip title={"Edytuj"}>
            <IconButton aria-label="Edytuj" onClick={handleEditOpen}> 
            <Edit />
            </IconButton>
        </Tooltip>
        <Tooltip title={"Usuń"}>
            <IconButton  aria-label="Usuń" onClick={handleDeleteOpen}>
            <DeleteOutline />
            </IconButton>
        </Tooltip>
      </React.Fragment>
      ) : selectedLicznik ? (
        <React.Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
          <DatePicker
            style={{width: "100%"}}  
            autoOk
            openTo="month"
            views={["year", "month"]}
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            id="search-date"
            value={selectedDate}
            onChange={handleDateChange}
            keyboardbuttonprops={{
              'aria-label': 'search-date',
            }}
            helperText={''}
          />
        </MuiPickersUtilsProvider>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Znajdź…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={searchval} 
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleSearch}
          />
        </div>
        <Tooltip title="Dodaj Odczyt">
          <IconButton aria-label="Dodaj" onClick={handleNewOpen}>
            <AddBox />
          </IconButton>
        </Tooltip>
        </React.Fragment>
      ) : null }
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const  headCells = [
  { id: 'data', numeric: false, disablePadding: true, label: 'Odczytany' },
  { id: 'odczyt', numeric: false, disablePadding: true, label: 'Odczyt m3' },
  { id: 'prognoza', numeric: false, disablePadding: true, label: 'Prognoza m3' },
];


function Odczyty(props) {
  const {  id_licznik, prognoza, odczyty, getOdczyty,  loading_od, loadingFnOd, clearFn, addOdczyt, editOdczyt } = props;
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('nazwa');
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [search, setSearch] = React.useState([]);
  const [searchval, setSearchval] = React.useState({search: ""});

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [addDate, setAdddDate] = React.useState(new Date());
  const [editDate, setEditDate] = React.useState(new Date());

  const [newOpen, setnewOpen] = React.useState(false);
  const [editOpen, seteditOpen] = React.useState({id: ''});
  const [editClicked, seteditClicked] = React.useState(false);
  const [deleteOpen, setdeleteOpen] = React.useState(false);

  const [formState, setFormState] = React.useState({odczyt: prognoza ? prognoza :"", odczytany: addDate ? (addDate.getFullYear()+'-'+("0" + (addDate.getMonth() + 1)).slice(-2)+'-'+("0" + (addDate.getDate())).slice(-2)): addDate, errors: {}});

  const [formStateEdit, setFormStateEdit] = React.useState({odczyt: prognoza ? prognoza :"", odczytany: editDate ? (editDate.getFullYear()+'-'+("0" + (editDate.getMonth() + 1)).slice(-2)+'-'+("0" + (editDate.getDate())).slice(-2)): editDate, errors: {}});


  const handleDateChange = (date) => {
    setSelectedDate(date);
    setAdddDate(date);
  };
  const handleAddDateChange = (date, keyboardInputValue) => {
    // not "invalid date"
    if (JSON.stringify(date) !== 'null') {
      setAdddDate(date);
      setFormState( formState => ({...formState, odczytany: (date.getFullYear()+'-'+("0" + (date.getMonth() + 1)).slice(-2)+'-'+("0" + (date.getDate())).slice(-2)) }));
    } else if (keyboardInputValue !== null){
      setAdddDate(date);
      setFormState( formState => ({...formState, odczytany: "" }));
    } else {
      setFormState( formState => ({...formState, odczytany: "" }));
      setAdddDate("");
    }
  };

  const handleEditDateChange = (date, keyboardInputValue) => {
    // not "invalid date"
    if (JSON.stringify(date) !== 'null') {
      setEditDate(date);
      setFormStateEdit( formStateEdit => ({...formStateEdit, odczytany: (date.getFullYear()+'-'+("0" + (date.getMonth() + 1)).slice(-2)+'-'+("0" + (date.getDate())).slice(-2)) }));
    } else if (keyboardInputValue !== null){
      setEditDate(date);
      setFormState( formStateEdit => ({...formStateEdit, odczytany: "" }));
    } else {
      setFormStateEdit( formStateEdit => ({...formStateEdit, odczytany: "" }));
      setEditDate("");
    }
  };

  const handleDeleteOpen = () => {
      setdeleteOpen(true);
  }
  const handleDeleteClose = async () => {
    setdeleteOpen(false);
    loadingFnOd(true);
    setSelected([]);
    await getOdczyty(id_licznik, (selectedDate.getFullYear()+'-'+("0" + (selectedDate.getMonth() + 1)).slice(-2)));
  }

  const handleNewOpen = () => {
      setnewOpen(true);
  }
  const handleNewClose = async () => {
    setnewOpen(false);
  }

  const handleAddOdczyt = async () => {
    if (formState.odczyt === "" || formState.odczyt.length < 1 || !formState.odczyt.match(/^[0-9\b]+$/)) {
      setFormState( formState => ({...formState, errors: {odczyt: 'błędna wartość' } }))
      return;
    } 
    if (formState.odczytany === "" || formState.odczytany.length < 10) {
      return;
    } 
    setnewOpen(false)
    loadingFnOd(true)
    clearFn();
    await addOdczyt(id_licznik, formState.odczyt, formState.odczytany, prognoza);
    getOdczyty(id_licznik, (selectedDate.getFullYear()+'-'+("0" + (selectedDate.getMonth() + 1)).slice(-2)));
    setFormState( formState => ({...formState, odczyt: prognoza? prognoza: "", errors: {odczyt: ''} }))
  }

  const handleEditOpen = () => {
    seteditClicked(true);
  }
  const handleEditClose = async () => {
    seteditClicked(false);
    seteditOpen({id: ''});
  }

  const handleEditOdczyt = async () => {
    if (formStateEdit.odczyt === "" || formStateEdit.odczyt.length < 1 || !formStateEdit.odczyt.toString().match(/^[0-9\b]+$/)) {
      setFormStateEdit( formStateEdit => ({...formStateEdit, errors: {odczyt: 'błędna wartość' } }))
      return;
    } 
    if (formStateEdit.odczytany === "" || formStateEdit.odczytany.length < 10) {
      return;
    } 
    seteditClicked(false);
    seteditOpen({id: ''});
    loadingFnOd(true)
    clearFn();
    await editOdczyt(editOpen.id, formStateEdit.odczyt, formStateEdit.odczytany, prognoza);
    getOdczyty(id_licznik, (selectedDate.getFullYear()+'-'+("0" + (selectedDate.getMonth() + 1)).slice(-2)));
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.id);
    if (selectedIndex === -1) {
        setSelected([row.id]);
        setSelectedRow(row);
    } else {
      setSelected([]);
      setSelectedRow({});
    }
  };
  const handleSearch = (event) => {
    event.persist();
    setSearchval(searchval => ({...searchval, search: event.target.value}));
  };

  const handleChange = (e) => {
    e.persist()
      setFormState( formState => ({
        ...formState, [e.target.id]: e.target.value, 
        errors: (e.target.id === 'odczyt' && e.target.value.length < 1) || (e.target.id === 'odczyt' &&  !e.target.value.match(/^[0-9\b]+$/)) ? { odczyt: 'błędna wartość' } : {}
      })
      )
  }

  const handleChangeEdit = (e) => {
    e.persist()
      setFormStateEdit( formStateEdit => ({
        ...formStateEdit, [e.target.id]: e.target.value, 
        errors: (e.target.id === 'odczyt' && e.target.value.length < 1) || (e.target.id === 'odczyt' &&  !e.target.value.match(/^[0-9\b]+$/)) ? { odczyt: 'błędna wartość' } : {}
      })
      )
  }

  useEffect(() => {
    if (selected.length !== 0) {
      const ret = rows.filter(r => r.id === selected[0]);
      setSelectedRow(ret[0]);
      if (editClicked) {
        seteditOpen({id: selectedRow.id});
        setFormStateEdit(formStateEdit => ({...formStateEdit, odczyt: ret[0].odczyt, odczytany: ret[0].data}))
        seteditOpen({id: ret[0].id});
      }
    } else {
      if (!editClicked) {
        seteditClicked(false);
        seteditOpen({id: ''});
        setFormStateEdit(formStateEdit => ({...formStateEdit, odczyt: '', odczytany: ''}))
        seteditOpen({id: ''});
      }
      setSelectedRow([]);
    }
    if (searchval.search === "") {
      setSearch(rows);
  } else {
      let rest = [];
      rows.forEach(function(row){
        return Object.keys(row).some(function(key){
            if(row[key] !== null){
              if (row[key].toString().toLowerCase().includes(searchval.search.toLowerCase())) {
                return rest.push(row);
              }
            }
            return false;
          })
      })
      if(rest.length === 0 ){
        setSearch([]);
      } else {
        setSearch(rest);
      }
  } 
  }, [rows, selected, editClicked, searchval, selectedRow.id]);
  
  useEffect(() => {
    if (prognoza !== undefined) {
      setFormState( formState => ({
        ...formState, odczyt: prognoza, 
      })
      )
      return
    }
}, [prognoza])

  useEffect(() => {
      if (id_licznik !== undefined) {
        seteditClicked(false);
        seteditOpen({id: ''});
        setSelected([]);
        clearFn();
        loadingFnOd(true)
        getOdczyty(id_licznik, (selectedDate.getFullYear()+'-'+("0" + (selectedDate.getMonth() + 1)).slice(-2)));
      } else {
        setSelected([]);
        setnewOpen(false)
        seteditClicked(false);
        seteditOpen({id: ''});
        setRows([]);
        return
      }
  }, [id_licznik, getOdczyty, clearFn, loadingFnOd, selectedDate])

  useEffect(() => {
    if (odczyty.length === 0 ) {
      seteditClicked(false);
      seteditOpen({id: ''});
      setnewOpen(false)
      setSelected([]);
      return  setRows([]);
    } else {
      return setRows(odczyty)
    }
}, [odczyty])


  const isSelected = id => selected.indexOf(id) !== -1;

  //const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
        <Card>
          <div className={classes.root}>
              {deleteOpen ? <DeleteDialog open={deleteOpen} handleClose={handleDeleteClose} rowselected={selectedRow}/> : null}
              <EnhancedTableToolbar 
                  selectedRow={selectedRow}
                  numSelected={selected.length}
                  handleSearch={handleSearch}
                  handleNewOpen={handleNewOpen}
                  handleEditOpen={handleEditOpen}
                  handleDeleteOpen={handleDeleteOpen}
                  selectedLicznik={id_licznik !== undefined? true: false}
                  searchval={searchval.search}
                  selectedDate={selectedDate}
                  handleDateChange={handleDateChange}
              />
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={'small'}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    headCells={headCells}
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                  {newOpen?
                      <TableRow>
                      <React.Fragment>
                      <TableCell align="left"  style={{width: "96px", padding: "0"}}>
                      <Tooltip title={"Zapisz"} onClick={handleAddOdczyt}>
                        <IconButton aria-label="Zapisz"> 
                          <CheckIcon  />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"Anuluj"}>
                        <IconButton aria-label="Anuluj" onClick={handleNewClose}> 
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                      </TableCell>
                      <TableCell  align="left">
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                          <KeyboardDatePicker
                            style={{width: "100%"}} 
                            openTo="date" 
                            autoOk
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="yyyy-MM-dd"
                            margin="normal"
                            id="odczytany"
                            value={addDate}
                            onChange={handleAddDateChange}
                            keyboardbuttonprops={{
                              'aria-label': 'odczytany',
                            }}
                            helperText={''}
                          />
                        </MuiPickersUtilsProvider>
                        </TableCell>
                          <TableCell align="left" >
                          <TextField
                              fullWidth
                              label="odczyt m3"
                              name="odczyt"
                              id="odczyt"
                              onChange={handleChange}
                              onFocus={handleChange}
                              onBlur={handleChange}
                              type="text"
                              value={formState.odczyt}
                              error={formState.errors.odczyt ? true : false  }
                              helperText={
                                formState.errors.odczyt ? 'błędna wartość' : null
                              }
                          />
                          </TableCell>
                          <TableCell align="left" >
                          <TextField
                              disabled
                              fullWidth
                              label="prognoza"
                              name="prognoza"
                              id="prognoza"
                              type="text"
                              value={prognoza ? prognoza : ''}
                          />
                          </TableCell>
                      </React.Fragment>
                    </TableRow> 
                  : null}
                  {search.length !== 0 && !loading_od ? ( 
                      stableSort( search, getComparator(order, orderBy))
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <React.Fragment key={row.id}>
                            {editOpen.id === row.id?
                            <React.Fragment>
                            <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          >
                            <TableCell align="left"  style={{width: "96px", padding: "0"}}>
                            <Tooltip title={"Zapisz"} onClick={handleEditOdczyt}>
                              <IconButton aria-label="Zapisz"> 
                                <CheckIcon  />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={"Anuluj"}>
                              <IconButton aria-label="Anuluj" onClick={handleEditClose}> 
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                            </TableCell>
                            <TableCell  align="left">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                          <KeyboardDatePicker
                            style={{width: "100%"}} 
                            openTo="date" 
                            autoOk
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="yyyy-MM-dd"
                            margin="normal"
                            id="edit-odczytany"
                            value={editDate}
                            onChange={handleEditDateChange}
                            keyboardbuttonprops={{
                              'aria-label': 'edit-odczytany',
                            }}
                            helperText={''}
                          />
                        </MuiPickersUtilsProvider>
                            </TableCell>
                             <TableCell align="left" >
                             <TextField
                                  fullWidth
                                  label="odczyt m3"
                                  name="odczyt"
                                  id="odczyt"
                                  onChange={handleChangeEdit}
                                  onFocus={handleChangeEdit}
                                  onBlur={handleChangeEdit}
                                  type="text"
                                  value={formStateEdit.odczyt}
                                  error={formStateEdit.errors.odczyt ? true : false  }
                                  helperText={
                                    formStateEdit.errors.odczyt ? 'błędna wartość' : null
                                  }
                              />
                             </TableCell>
                             <TableCell align="left" >
                              <TextField
                                  disabled
                                  fullWidth
                                  label="prognoza"
                                  name="prognoza"
                                  id="prognoza"
                                  type="text"
                                  value={prognoza ? prognoza : ''}
                                />
                             </TableCell>
                             </TableRow>
                            </React.Fragment>
                            : 
                              <React.Fragment>
                              <TableRow
                              hover
                              onClick={event => handleClick(event, row)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                                 <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </TableCell>
                                
                                <TableCell  id={labelId} scope="row" align="left">{row.data}</TableCell>
                                <TableCell align="left" >{row.odczyt}</TableCell>
                                <TableCell align="left" >{row.prognoza}</TableCell>
                                </TableRow>
                              </React.Fragment>
                            }
                           </React.Fragment>
                          
                        );
                      })
                      ) : (!newOpen) ?
                      <TableRow>
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell style={{verticalAlign: "middle", left: "35%", position: "sticky", height: "70px"}}>{loading_od ? (<CircularProgress size={40}/>) : "Brak danych" }</TableCell>
                      </TableRow>
                    :  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell style={{verticalAlign: "middle", left: "35%", position: "sticky", height: "70px"}}>{loading_od ? (<CircularProgress size={40}/>) : "Brak danych" }</TableCell>
                  </TableRow>
                  }
                  </TableBody>
                </Table>
              </TableContainer>
          </div>
        </Card>
  );
}

const mapStateToProps = state => ({
  odczyty: state.app_reducer.odczyty,
  loading_od: state.app_reducer.loading_od,
  });

export default connect( mapStateToProps,
  { getOdczyty, loadingFnOd, clearFn, addOdczyt, editOdczyt})(Odczyty);