import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import { connect } from "react-redux";
import { addNieruchomosc, loadingFn, clearFn } from "../../../../actions/appActions";

import scrollIntoView from 'scroll-into-view-if-needed';

import clsx from 'clsx';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  TextField
} from '@material-ui/core';

const useStyles = theme => ({
    root: {
        padding: theme.spacing(4)
      },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      color: "white",
      flex: 1,
    },
  });
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  class New extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {},
            nazwa: "",
            adres: "",
            pow_uzyt: "",
            pow_komorki: "",
            pow_parking: "",
            udzial: "",
            liczba_osob: "",
            co: "",
            cw: "",
            zw: "",
            ekspluatacja: "",
            fr: "",
            smieci: "",
            errors: {},
            loading: false
        }
    };

    handleChange = e => {
        const { errors } = this.state;
        e.persist();
        this.setState(
            (e.target.value === "" && e.target.id === "nazwa") ||
              (e.target.id === "nazwa" && e.target.value.length < 3) ||
              (e.target.id === "nazwa" && e.target.value.length > 255) 
            ? {
            
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: 'błędna wartość min 3 znaki max 255'
                },
              }
            : (e.target.value === "" && e.target.id === "adres") ||
            (e.target.id === "adres" && e.target.value.length < 3) ||
            (e.target.id === "adres" && e.target.value.length > 255) 
            ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
              }
            : (e.target.value === "" && e.target.id === "pow_uzyt") ||
            (e.target.id === "pow_uzyt" && e.target.value.length > 10) 
              ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
                }
            : (e.target.value === "" && e.target.id === "pow_komorki") ||
            (e.target.id === "pow_komorki" && e.target.value.length > 10) 
            ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
                }
            : (e.target.value === "" && e.target.id === "pow_parking") ||
            (e.target.id === "pow_parking" && e.target.value.length > 10) 
            ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
              }
            : (e.target.value === "" && e.target.id === "liczba_osob") ||
            (e.target.id === "liczba_osob" && !e.target.value.match(/^[0-9\b]+$/)) ||
            (e.target.id === "liczba_osob" && e.target.value.length > 10) 
            ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
            }
            : (e.target.value === "" && e.target.id === "udzial") ||
            (e.target.id === "udzial" && !e.target.value.match(/^[0-9\b]+$/)) ||
            (e.target.id === "udzial" && e.target.value.length > 3) 
            ? {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: true
                },
              }
              : (e.target.value === "" && e.target.id === "co") ||
              (e.target.id === "co" && e.target.value.length > 10) 
              ? {
                  [e.target.id]: e.target.value,
                  errors: {
                      ...errors,
                      [e.target.id]: true
                  },
                  }
                : (e.target.value === "" && e.target.id === "cw") ||
                (e.target.id === "cw" && e.target.value.length > 10) 
                ? {
                    [e.target.id]: e.target.value,
                    errors: {
                        ...errors,
                        [e.target.id]: true
                    },
                    }
                : (e.target.value === "" && e.target.id === "zw") ||
                (e.target.id === "zw" && e.target.value.length > 10) 
                ? {
                    [e.target.id]: e.target.value,
                    errors: {
                        ...errors,
                        [e.target.id]: true
                    },
                    }
                : (e.target.value === "" && e.target.id === "fr") ||
                (e.target.id === "fr" && e.target.value.length > 10) 
                ? {
                    [e.target.id]: e.target.value,
                    errors: {
                        ...errors,
                        [e.target.id]: true
                    },
                    }
                : (e.target.value === "" && e.target.id === "smieci") ||
                (e.target.id === "smieci" && e.target.value.length > 10) 
                ? {
                    [e.target.id]: e.target.value,
                    errors: {
                        ...errors,
                        [e.target.id]: true
                    },
                    }
                : (e.target.value === "" && e.target.id === "ekspluatacja") ||
                (e.target.id === "ekspluatacja" && e.target.value.length > 10) 
                ? {
                    [e.target.id]: e.target.value,
                    errors: {
                        ...errors,
                        [e.target.id]: true
                    },
                    }
            : {
                [e.target.id]: e.target.value,
                errors: {
                    ...errors,
                    [e.target.id]: false
                },
            }
        );
    };

    handleSave = async (e) => {
        e.preventDefault();
        const {
            nazwa,
            adres,
            pow_uzyt,
            pow_komorki,
            pow_parking,
            udzial,
            liczba_osob,
            co,
            cw,
            zw,
            ekspluatacja,
            fr,
            smieci,
            errors
          } = this.state;

        if (nazwa === "" || nazwa.length < 3 || nazwa.length > 255) {
            this.setState({ 
                errors: { ...errors, nazwa: 'błędna wartość min 3 znaki max 255' }
            });
            const node = document.getElementById('nazwa');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        if (adres === "" || adres.length < 3 || adres.length > 255) {
            this.setState({ 
                errors: { ...errors, adres: true }
            });
            const node = document.getElementById('adres');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        if (pow_uzyt === "" || pow_uzyt.length > 10) {
            this.setState({ 
                errors: { ...errors, pow_uzyt: true }
            });
            const node = document.getElementById('pow_uzyt');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        if (pow_komorki === "" || pow_komorki.length > 10) {
            this.setState({ 
                errors: { ...errors, pow_komorki: true }
            });
            const node = document.getElementById('pow_komorki');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        if ( pow_parking === "" || pow_parking.length > 10) {
            this.setState({ 
                errors: { ...errors,  pow_parking: true }
            });
            const node = document.getElementById('pow_parking');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        } 
        if ( liczba_osob === "" || liczba_osob.length > 10 || !liczba_osob.match(/^[0-9\b]+$/)) {
            this.setState({ 
                errors: { ...errors,  liczba_osob: true }
            });
            const node = document.getElementById('liczba_osob');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        }
        if ( udzial === "" || udzial.length > 10 || !udzial.match(/^[0-9\b]+$/)) {
            this.setState({ 
                errors: { ...errors,  udzial: true }
            });
            const node = document.getElementById('udzial');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        }
       
        if ( co === "" || co.length > 10) {
            this.setState({ 
                errors: { ...errors,  co: true }
            });
            const node = document.getElementById('co');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        }
        if ( zw === "" || zw.length > 10) {
            this.setState({ 
                errors: { ...errors,  zw: true }
            });
            const node = document.getElementById('zw');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        }
        if ( cw === "" || cw.length > 10) {
            this.setState({ 
                errors: { ...errors,  cw: true }
            });
            const node = document.getElementById('cw');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        }
        if ( smieci === "" || smieci.length > 10) {
            this.setState({ 
                errors: { ...errors,  smieci: true }
            });
            const node = document.getElementById('smieci');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        }
        if ( ekspluatacja === "" || ekspluatacja.length > 10) {
            this.setState({ 
                errors: { ...errors,  ekspluatacja: true }
            });
            const node = document.getElementById('ekspluatacja');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        }
        if ( fr === "" || fr.length > 10) {
            this.setState({ 
                errors: { ...errors,  fr: true }
            });
            const node = document.getElementById('fr');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            return;
        }
        await this.setState({
            values: {
                nazwa: nazwa,
                adres: adres,
                pow_uzyt: pow_uzyt,
                pow_komorki: pow_komorki,
                pow_parking: pow_parking,
                udzial: udzial,
                liczba_osob: liczba_osob,
                co: co,
                cw: cw,
                zw: zw,
                ekspluatacja: ekspluatacja,
                fr: fr,
                smieci: smieci,
            },
            loading: true 
        });
        
       // await this.props.checkNazwa(this.state.values.nazwa);
       this.props.clearFn();
       this.props.loadingFn(true);
        await this.props.addNieruchomosc(this.state.values);
        if (this.props.error_axios) {  
            this.setState({ 
                errors: { ...errors, nazwa: "nazwa już istnieje" }
            });
            const node = document.getElementById('nazwa');
            scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
            this.setState({
                loading: false 
            });
            return;
        } 
        this.props.handleClose();
        this.setState({
            isValid: false,
            nazwa: "",
            adres: "",
            pow_uzyt: "",
            pow_komorki: "",
            pow_parking: "",
            udzial: "",
            liczba_osob: "",
            co: "",
            cw: "",
            zw: "",
            ekspluatacja: "",
            fr: "",
            smieci: "",
            errors: {},
            loading: false 
        });

    }
    render() {
        const { classes, addNieruchomosc, checkNazwa, loadingFn, clearFn, handleClose, error_axios, success_axios, open, className, ...rest } = this.props;
        const { loading, errors, nazwa, adres, pow_komorki, pow_uzyt, pow_parking, udzial, liczba_osob, co, cw, zw, smieci, ekspluatacja, fr } = this.state

        return (
            <div>
            <Dialog fullScreen open={open} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" disabled={loading ? true : false}>
                    <CloseIcon />
                    </IconButton>
                    <Typography variant="h3" className={classes.title}>
                    Dodaj nową Nieruchomość
                    </Typography>
                    <Button autoFocus color="primary" style={{ backgroundColor: "aliceblue" }} variant="outlined" onClick={this.handleSave} disabled={loading ? true : false}>
                    Zapisz
                    </Button>
                </Toolbar>
                </AppBar>
                <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                item
                md={6}
                xs={12}
                >
                    <Card
                        {...rest}
                        className={clsx(className)}
                    >
                    <form>
                        <CardHeader
                        title="Dane Nieruchomości"
                        />
                        <Divider />
                        <CardContent>
                        <TextField
                            required
                            fullWidth
                            label="Nazwa"
                            name="nazwa"
                            id="nazwa"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            type="text"
                            value={nazwa}
                            variant="outlined"
                            error={errors.nazwa ? true : false}
                            helperText={
                                errors.nazwa ?  errors.nazwa : null
                            }
                            disabled={ loading ? true : false}
                        />
                        <TextField
                            fullWidth
                            label="Adres"
                            name="adres"
                            id="adres"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="text"
                            value={adres}
                            variant="outlined"
                            error={errors.adres ? true : false  }
                            helperText={
                                errors.adres ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Powierzchnia użytkowa m2"
                            name="pow_uzyt"
                            id="pow_uzyt"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="number"
                            value={pow_uzyt}
                            variant="outlined"
                            error={errors.pow_uzyt ? true : false  }
                            helperText={
                                errors.pow_uzyt ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Powierzchnia komórki m2"
                            name="pow_komorki"
                            id="pow_komorki"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="number"
                            value={pow_komorki}
                            variant="outlined"
                            error={errors.pow_komorki ? true : false  }
                            helperText={
                                errors.pow_komorki ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Powierzchnia Parkingowa m2"
                            name="pow_parking"
                            id="pow_parking"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="number"
                            value={pow_parking}
                            variant="outlined"
                            error={errors.pow_parking ? true : false  }
                            helperText={
                                errors.pow_parking ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Liczba osób"
                            name="liczba_osob"
                            id="liczba_osob"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="number"
                            value={liczba_osob}
                            variant="outlined"
                            error={errors.liczba_osob ? true : false  }
                            helperText={
                                errors.liczba_osob ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Udział %"
                            name="udzial"
                            id="udzial"
                            onChange={this.handleChange}
                            onFocus={this.handleChange}
                            onBlur={this.handleChange}
                            style={{ marginTop: '1rem' }}
                            type="number"
                            value={udzial}
                            variant="outlined"
                            error={errors.udzial ? true : false  }
                            helperText={
                                errors.udzial ? 'błędna wartość' : null
                            }
                            disabled={ loading ? true : false}
                        />
                        </CardContent>
                    </form>
                    </Card>
                </Grid>
                <Grid
                item
                md={6}
                xs={12}
                >
                <Card
                {...rest}
                className={clsx(className)}
                >
                <form>
                    <CardHeader
                    title="Stawki"
                    />
                    <Divider />
                    <CardContent>
                    <TextField
                        required
                        fullWidth
                        label="Centralne Ogrzewanie"
                        name="co"
                        id="co"
                        onChange={this.handleChange}
                        onFocus={this.handleChange}
                        onBlur={this.handleChange}
                        type="number"
                        value={co}
                        variant="outlined"
                        error={errors.co ? true : false  }
                        helperText={
                            errors.co ? 'błędna wartość' : null
                        }
                        disabled={ loading ? true : false}
                    />
                    <TextField
                        required
                        fullWidth
                        label="Zimna Woda"
                        name="zw"
                        id="zw"
                        onChange={this.handleChange}
                        onFocus={this.handleChange}
                        onBlur={this.handleChange}
                        style={{ marginTop: '1rem' }}
                        type="number"
                        value={zw}
                        variant="outlined"
                        error={errors.zw ? true : false  }
                        helperText={
                            errors.zw ? 'błędna wartość' : null
                        }
                        disabled={ loading ? true : false}
                    />
                    <TextField
                        required
                        fullWidth
                        label="Ciepła Woda"
                        name="cw"
                        id="cw"
                        onChange={this.handleChange}
                        onFocus={this.handleChange}
                        onBlur={this.handleChange}
                        style={{ marginTop: '1rem' }}
                        type="number"
                        value={cw}
                        variant="outlined"
                        error={errors.cw ? true : false  }
                        helperText={
                            errors.cw ? 'błędna wartość' : null
                        }
                        disabled={ loading ? true : false}
                    />
                    <TextField
                        required
                        fullWidth
                        label="Śmieci"
                        name="smieci"
                        id="smieci"
                        onChange={this.handleChange}
                        onFocus={this.handleChange}
                        onBlur={this.handleChange}
                        style={{ marginTop: '1rem' }}
                        type="number"
                        value={smieci}
                        variant="outlined"
                        error={errors.smieci ? true : false  }
                        helperText={
                            errors.smieci ? 'błędna wartość' : null
                        }
                        disabled={ loading ? true : false}
                    />
                    <TextField
                        required
                        fullWidth
                        label="Ekspluatacja"
                        name="ekspluatacja"
                        id="ekspluatacja"
                        onChange={this.handleChange}
                        onFocus={this.handleChange}
                        onBlur={this.handleChange}
                        style={{ marginTop: '1rem' }}
                        type="number"
                        value={ekspluatacja}
                        variant="outlined"
                        error={errors.ekspluatacja ? true : false  }
                        helperText={
                            errors.ekspluatacja ? 'błędna wartość' : null
                        }
                        disabled={ loading ? true : false}
                    />
                    <TextField
                        required
                        fullWidth
                        label="Fundusz Remontowy"
                        name="fr"
                        id="fr"
                        onChange={this.handleChange}
                        onFocus={this.handleChange}
                        onBlur={this.handleChange}
                        style={{ marginTop: '1rem' }}
                        type="number"
                        value={fr}
                        variant="outlined"
                        error={errors.fr ? true : false  }
                        helperText={
                            errors.fr ? 'błędna wartość' : null
                        }
                        disabled={ loading ? true : false}
                    />
                    </CardContent>
                </form>
                </Card>
                </Grid>
            </Grid>
            </div>
            
            </Dialog>
            </div>
        );
    }
};

const mapStateToProps = state => ({
    error_axios: state.app_reducer.error_axios,
    success_axios: state.app_reducer.success_axios
  });

export default withStyles(useStyles)(connect(
    mapStateToProps,
{ addNieruchomosc, loadingFn, clearFn }
)(New));