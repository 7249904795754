import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery, Typography } from '@material-ui/core';
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { Sidebar, Topbar, Footer } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 40,

    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 40
    }
  },
  shiftContent: {
    paddingLeft: 160
  },
  content: {
    height: '100%'
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const Main = props => {
  window.scrollTo(0, 0);
  const { children, history, error_axios, success_axios, loading } = props;
  const [openSidebar, setOpenSidebar ] = useState(false);
  const [openSnackBarError, setSnackBarError ] = useState(false);
  const [openSnackBarSuccess, setSnackBarSuccess ] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  useEffect(() => {
    if (error_axios) {
      setSnackBarError(true)
    } else {
      setSnackBarError(false)
    }
    if (!success_axios) {
      setSnackBarSuccess(false)
    } else {
      setSnackBarSuccess(true)
    }
  }, [error_axios, success_axios]);

  const handleCloseMainError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarError(false);
  };
  const handleCloseMainSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarSuccess(false);
  };

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };


  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
  
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
    {loading? (<div style={{ 
      position: "fixed", 
      zIndex: "2000", 
      paddingTop: "100px",
      left: "0",
      top: "0",
      right: "0",
      bottom: "0",
      width: "100%", 
      height: "100%", 
      overflow: "auto",
      backgroundColor: "rgba(0,0,0,0.4)"
      }}>
        <div style={{ textAlign: "center", margin: "auto", paddingTop: "14%"}}>
          <CircularProgress
            size={200}
          />
        </div>
      </div>) : null}
      <Topbar onSidebarOpen={handleSidebarOpen} history={history} />
      <Sidebar
        history={history}
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
      <Snackbar open={openSnackBarError} autoHideDuration={6000} onClose={handleCloseMainError} anchorOrigin={{vertical: 'bottom',  horizontal: 'right'}}>
        <Alert onClose={handleCloseMainError} severity="error">
          <Typography
            style={{color:"white"}}
            variant="h4"
          >
        {error_axios}
      </Typography>
        </Alert>
      </Snackbar>
      <Snackbar open={openSnackBarSuccess} autoHideDuration={6000} onClose={handleCloseMainSuccess} anchorOrigin={{vertical: 'top',  horizontal: 'center'}}>
        <Alert onClose={handleCloseMainSuccess} severity="success">
          <Typography
            style={{color:"white"}}
            variant="h4"
          >
            {success_axios}
          </Typography>
        </Alert>
      </Snackbar>
    </div>

  );
};

Main.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = state => ({
    loading: state.app_reducer.loading,
    error_axios: state.app_reducer.error_axios,
    success_axios: state.app_reducer.success_axios
  });

export default connect(
  mapStateToProps,
{  }
)(withRouter(Main));
