import React, { Component } from 'react';
import AuthRoutes from '../AuthRoutes';
import Routes from '../Routes';

import { connect } from "react-redux";
import { checkAuth } from "../actions/appActions";

class AuthComponent extends Component {

    async UNSAFE_componentWillMount() {
        const token = localStorage.getItem('jwtToken');
        if (token && !this.props.isAuthenticated) {
            await this.props.checkAuth(token);
        }
    }

    render () {
        return ( (!this.props.isAuthenticated && !localStorage.getItem('jwtToken'))? <Routes /> : <AuthRoutes />)
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.app_reducer.isAuthenticated
  });

export default connect(
   mapStateToProps,
{ checkAuth }
)(AuthComponent);