import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import Lokator from './Lokator';
import { connect } from "react-redux";
import { getLokatorzy, loadingFn, clearFn } from "../../actions/appActions";

const useStyles = theme => ({
    root: {
        paddingTop: "32px",
        paddingRight: "2px",
        paddingLeft: "21px",
        [theme.breakpoints.down('md')]: {
          paddingLeft: "2px",
        }
      }
  });

class Lokatorzy extends React.Component {
    constructor() {
        super();
        this.handleRefresh = this.handleRefresh.bind(this);

    };

    
    async handleRefresh() {
        const { id } = this.props.match.params;
        this.props.loadingFn(true);
        await this.props.getLokatorzy(id);
    }

    async UNSAFE_componentWillMount() {
        const { id } = this.props.match.params;
        this.props.clearFn();
        this.props.loadingFn(true);
        await this.props.getLokatorzy(id);
    }

    render() {
        const { lokatorzy, liczniki, classes } = this.props;
        const rows = lokatorzy.map(row => ({ id: row.id, id_nieruchomosc: row.id_nieruchomosc, nazwa: row.nazwa, lokal: row.lokal, imie: row.imie, nazwisko: row.nazwisko, pow_uzyt: row.pow_uzyt, pow_komorki: row.pow_komorki, pow_parking: row.pow_parking, adres: row.adres, liczba_osob: row.liczba_osob, udzial: row.udzial, email: row.email, tel: row.tel, kom: row.kom, uwagi: row.uwagi, nr_bank: row.nr_bank, co: row.co, cw: row.cw, zw: row.zw, fr: row.fr, smieci: row.smieci, ekspluatacja: row.ekspluatacja}));

        const { id, nazwa } = this.props.match.params;

        const headCells = [
            { id: 'lokal', numeric: false, disablePadding: true, label: 'Lokal' },
            { id: 'nazwa', numeric: false, disablePadding: true, label: 'Nieruchomość' },
          ];
        return (
           
            <div className={classes.root}>
                <Typography variant="h4" style={{paddingLeft: "10px", paddingBottom: "10px"}}>Lokatorzy {nazwa}</Typography>
                <Lokator headCells={headCells} rows={rows} handleRefresh={this.handleRefresh} id_match_params={id} liczniki={liczniki}/>
            </div>

           
        );
    }
};

const mapStateToProps = state => ({
    lokatorzy: state.app_reducer.lokatorzy,
    });

export default withStyles(useStyles)(connect( mapStateToProps,
    { getLokatorzy, loadingFn, clearFn })(Lokatorzy));
