import React, { forwardRef }  from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton, Typography, colors } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';

import { connect } from "react-redux";
import { logout, loadingFn, clearFn } from "../../../../actions/appActions";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  active: {
    color: colors.lightBlue[200]
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
  >
    <RouterLink {...props} />
  </div>
));

const Topbar = props => {
  const { className, onSidebarOpen, history, logout, loadingFn, clearFn, ...rest } = props;
  const classes = useStyles();

  const onClick = (event) => {
      event.preventDefault();
      clearFn();
      loadingFn(true);
      logout();
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
  <Typography variant="h5" style={{color: "white"}}>ADM Laskowski</Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
          <Tooltip title={"Nieruchomości"}>
           <IconButton
           activeClassName={classes.active}
           component={CustomRouterLink}
            className={classes.signOutButton}
            color="inherit"
            to="/nieruchomosci"
            >
              <DashboardIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Lokatorzy"}>
           <IconButton
           activeClassName={classes.active}
           component={CustomRouterLink}
            className={classes.signOutButton}
            color="inherit"
            to="/lokatorzy"
            >
              <PeopleIcon />
            </IconButton>
          </Tooltip>
        <Hidden mdDown>
          <Tooltip title={"Logout"}>
           <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={onClick}
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  history: PropTypes.object,

};

export default connect(
 null,
{ logout, loadingFn, clearFn }
)(Topbar);
