import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Nieruchomosci as NieruchomosciView,
  Lokatorzy as LokatorzyView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  NotFound as NotFoundView
} from './views';

const AuthRoutes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={NieruchomosciView}
        exact
        layout={MainLayout}
        path="/"
      />
      <RouteWithLayout
        component={NieruchomosciView}
        exact
        layout={MainLayout}
        path="/login"
      />
      <RouteWithLayout
        component={NieruchomosciView}
        exact
        layout={MainLayout}
        path="/nieruchomosci"
      />
      <RouteWithLayout
        component={LokatorzyView}
        exact
        layout={MainLayout}
        path="/lokatorzy"
      />
      <RouteWithLayout
        component={LokatorzyView}
        exact
        layout={MainLayout}
        path="/lokatorzy/:id/:nazwa"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default AuthRoutes;
