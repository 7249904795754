import React, {useEffect, forwardRef} from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, useTheme, fade } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';

import InputBase from '@material-ui/core/InputBase';
import AddBox from '@material-ui/icons/AddBox';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Card, Grid } from '@material-ui/core';

import NewDialog from './New';
import EditDialog from './Edit';
import DeleteDialog from './Delete';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const { headCells, classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='default' style={{width: "50px", maxWidth: "50px", minWidth: "50px"}}></TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: "48px"
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  search: {
    backgroundColor: "aliceblue",
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'black',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
  >
    <RouterLink {...props} />
  </div>
));
const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { selectedRow, numSelected, handleSearch, handleNewOpen, handleEditOpen, handleDeleteOpen, searchval } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
         {selectedRow.nazwa}
        </Typography>
      ) : <Typography className={classes.title} color="inherit" variant="subtitle1">
     </Typography>}

      {numSelected > 0 ? (
        <React.Fragment>
        <Tooltip title={"Edytuj"}>
            <IconButton aria-label="Edytuj" onClick={handleEditOpen}> 
            <Edit />
            </IconButton>
        </Tooltip>
        <Tooltip title={"Lokatorzy"}>
            <IconButton aria-label="Lokatorzy"  component={CustomRouterLink}
            to={"/lokatorzy/"+selectedRow.id+'/'+selectedRow.nazwa}>
            <AccessibilityNewIcon  />
            </IconButton>
        </Tooltip>
        <Tooltip title={"Usuń"}>
            <IconButton  aria-label="Usuń" onClick={handleDeleteOpen}>
            <DeleteOutline />
            </IconButton>
        </Tooltip>
      </React.Fragment>
      ) : (
        <React.Fragment>
        <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Znajdź…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          id="id_search"
          value={searchval} 
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearch}
        />
      </div>
        <Tooltip title="Dodaj Nieruchomość">
          <IconButton aria-label="Dodaj" onClick={handleNewOpen}>
            <AddBox />
          </IconButton>
        </Tooltip>
        </React.Fragment>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      margin: 0,
    },
  }));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = event => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = event => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = event => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = event => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="pierwsza"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="następna"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="ostatnia"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
}

function DataTable(props) {
    const { rows, headCells, handleRefresh } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = React.useState([]);
  const [searchval, setSearchval] = React.useState({search: ""});
  const [newOpen, setnewOpen] = React.useState(false);
  const [editOpen, seteditOpen] = React.useState(false);
  const [deleteOpen, setdeleteOpen] = React.useState(false);
  const [valuetab, setValueTab] = React.useState(0);

  const changeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleDeleteOpen = () => {
    setdeleteOpen(true);
}
const handleDeleteClose = async () => {
  setdeleteOpen(false);
  setSelectedRow([]);
  setSelected([]);
  await handleRefresh();
}


  const handleNewOpen = () => {
      setnewOpen(true);
  }
  const handleNewClose = async () => {
    setnewOpen(false);
    await handleRefresh();
  }

  const handleEditOpen = () => {
    seteditOpen(true);
  }
  const handleEditClose = async () => {
  seteditOpen(false);
  await handleRefresh();
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.id);
    if (selectedIndex === -1) {
        setSelected([row.id]);
        setSelectedRow(row);
    } else {
      setSelected([]);
      setSelectedRow({});
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    event.persist();
   setSearchval(searchval => ({...searchval, search: event.target.value}));
  };

  useEffect(() => {
    if (selected.length !== 0) {
      const ret = rows.filter(r => r.id === selected[0]);
      setSelectedRow(ret[0]);
    } else {
      setSelectedRow([]);
    }
  if (searchval.search === "") {
      setSearch(rows);
  } else {
      let rest = [];
      rows.forEach(function(row){
        return Object.keys(row).some(function(key){
            if(row[key] !== null){
              if (row[key].toString().toLowerCase().includes(searchval.search.toLowerCase())) {
                return rest.push(row);
              }
            }
            return false;
          })
      })
      if(rest.length === 0 ){
        setSearch([]);
      } else {
        setSearch(rest);
      }
  } 
  }, [rows, selected, searchval]);

  const isSelected = id => selected.indexOf(id) !== -1;

  //const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
      item
      lg={4}
      md={5}
      xl={3}
      xs={12}
      >
        <Card>
    <div className={classes.root}>
        <NewDialog open={newOpen} handleClose={handleNewClose}/>
        {editOpen ? <EditDialog open={editOpen} handleClose={handleEditClose} rowid={selectedRow.id}/> : null}
        {deleteOpen ? <DeleteDialog open={deleteOpen} handleClose={handleDeleteClose} rowselected={selectedRow}/> : null}
        <EnhancedTableToolbar 
            selectedRow={selectedRow}
            numSelected={selected.length}
            handleSearch={handleSearch}
            handleNewOpen={handleNewOpen}
            handleEditOpen={handleEditOpen}
            handleDeleteOpen={handleDeleteOpen}
            searchval={searchval.search}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
            {search.length !== 0 ? ( 
                stableSort( search, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell align="left" id={labelId} scope="row">{row.id}</TableCell>
                      <TableCell align="left">{row.nazwa}</TableCell>
                      <TableCell align="left">{row.adres}</TableCell>
                    </TableRow>
                  );
                })
                ) :  
                <TableRow role="checkbox">
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell style={{verticalAlign: "middle", left: "40%", position: "sticky"}}>Brak danych</TableCell>  
                </TableRow>
            }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25,  { label: 'Wszystkie', value: rows.length }]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage=""
          ActionsComponent={TablePaginationActions} 
          labelDisplayedRows={({ from, to, count }) => 
            'od '.concat(
            `${from}`,
            " do ",
            `${to === -1 ? count : to}`,
            ' z ',
            `${count !== -1 ? count : 'więcej od '.concat(`${to}`)}`
              )
          }
        />
    </div>
    </Card>
    </Grid>
     <Grid
     item
     lg={8}
     md={7}
     xl={9}
     xs={12}
     >
     <Card>
     <Tabs
       value={valuetab}
       indicatorColor="primary"
       textColor="primary"
       onChange={changeTab}
       aria-label="disabled tabs example"
     >
       <Tab label="Dane Nieruchomości" {...a11yProps(0)}/>
       <Tab label="Stawki" {...a11yProps(1)}/>
       <Tab label="Naliczenia" {...a11yProps(2)}/>
     </Tabs>
     <TabPanel value={valuetab} index={0} style={{minHeight: "238px"}}>
       <TableContainer>
         <Table>
           <TableBody>
           <TableRow>
           <TableCell align="left" style={{width: "200px", padding: 6}}>Nr. Niruchomości:</TableCell>
           <TableCell align="left" style={{ padding: 6}}>{selectedRow.nazwa}</TableCell>
           </TableRow>
           <TableRow>
           <TableCell align="left" style={{width: "200px", padding: 6}}>Pow. użytkowa m2:</TableCell>
           <TableCell align="left" style={{ padding: 6}}>{selectedRow.pow_uzyt}</TableCell>
           </TableRow>
           <TableRow>
           <TableCell align="left" style={{width: "200px", padding: 6}}>Pow. Komórki m2:</TableCell>
           <TableCell align="left" style={{ padding: 6}}>{selectedRow.pow_komorki}</TableCell>
           </TableRow>
           <TableRow>
           <TableCell align="left" style={{width: "200px", padding: 6}}>Pow. Parkingowa m2:</TableCell>
           <TableCell align="left" style={{ padding: 6}}>{selectedRow.pow_parking}</TableCell>
           </TableRow>
           <TableRow>
           <TableCell align="left" style={{width: "200px", padding: 6}}> Udział %:</TableCell>
           <TableCell align="left" style={{ padding: 6}}>{selectedRow.udzial}</TableCell>
           </TableRow>
           </TableBody>
         </Table>
       </TableContainer>
     </TabPanel>
     <TabPanel value={valuetab} index={1} style={{minHeight: "238px"}}>
       <TableContainer>
         <Table>
           <TableBody>
           <TableRow >
           <TableCell align="left" style={{width: "200px", padding: 6}}>Centralne Ogrzewanie:</TableCell>
           <TableCell align="left" style={{ padding: 6}}>{selectedRow.co}</TableCell>
           </TableRow>
           <TableRow>
           <TableCell align="left" style={{width: "200px", padding: 6}}>Ciepła Woda:</TableCell>
           <TableCell align="left" style={{ padding: 6}}>{selectedRow.cw}</TableCell>
           </TableRow>
           <TableRow>
           <TableCell align="left" style={{width: "200px", padding: 6}}>Zimna Woda:</TableCell>
           <TableCell align="left" style={{ padding: 6}}>{selectedRow.zw}</TableCell>
           </TableRow>
           <TableRow>
           <TableCell align="left" style={{width: "200px", padding: 6}}>Fundusz Remontowy:</TableCell>
           <TableCell align="left" style={{ padding: 6}}>{selectedRow.fr}</TableCell>
           </TableRow>
           <TableRow>
           <TableCell align="left" style={{width: "200px", padding: 6}}>Śmieci:</TableCell>
           <TableCell align="left" style={{ padding: 6}}>{selectedRow.smieci}</TableCell>
           </TableRow>
           <TableRow>
           <TableCell align="left" style={{width: "200px", padding: 6}}>Ekspluatacja:</TableCell>
           <TableCell align="left" style={{ padding: 6}}>{selectedRow.ekspluatacja}</TableCell>
           </TableRow>
           </TableBody>
         </Table>
       </TableContainer>
     </TabPanel>
     </Card>
     </Grid>
   </Grid>
  );
}
export default DataTable;