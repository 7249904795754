import {
    ERROR_AXIOS,
    AUTH_APP,
    AUTH_APP_LOGOUT,
    APP_LOADING,
    SM_LOADING,
    GET_NIERUCHOMOSCI,
    GET_NIERUCHOMOSC,
    ADD_NIERUCHOMOSC,
    EDIT_NIERUCHOMOSC,
    DELETE_NIERUCHOMOSC,
    CLEAR_RESPONSE,
    GET_LOKATORZY,
    ADD_LOKATOR,
    DELETE_LOKATOR,
    EDIT_LOKATOR,
    GET_LOKATOR,
    GET_LICZNIKI,
    ADD_LICZNIK,
    EDIT_LICZNIK,
    DELETE_LICZNIK,
    OD_LOADING,
    GET_ODCZYTY,
    ADD_ODCZYT,
    EDIT_ODCZYT,
    DELETE_ODCZYT
  } from "../actions/types";

  const initialState = {
    isAuthenticated: false,
    loading: false,
    loading_sm: false,
    loading_od: false,
    error_axios: "",
    success_axios: "",
    nieruchomosci: [],
    nieruchomosc: {},
    lokatorzy: [],
    liczniki: [],
    odczyty: [],
    lokator: {},
    light_nieruchomosci: {}
  };

  export default function(state = initialState, action) {
    switch (action.type) {
      case SM_LOADING:
        return {
          ...state,
          loading_sm: action.payload,
        }
        case OD_LOADING:
        return {
          ...state,
          loading_od: action.payload,
        }
        case GET_ODCZYTY:
          return {
            ...state,
            odczyty: action.payload.data,
            loading_od: false,
          }
        case ADD_ODCZYT:
          return {
            ...state,
            loading_od: false,
            success_axios: action.payload,
            error_axios: ""
          }
          case EDIT_ODCZYT:
          return {
            ...state,
            loading_od: false,
            success_axios: action.payload,
            error_axios: ""
          }
          case DELETE_ODCZYT:
          return {
            ...state,
            loading_od: false,
            loading: false,
            success_axios: action.payload,
            error_axios: ""
          }
      case ADD_LICZNIK:
        return {
          ...state,
          loading_sm: false,
          success_axios: action.payload,
          error_axios: ""
        }
        case EDIT_LICZNIK:
        return {
          ...state,
          loading_sm: false,
          success_axios: action.payload,
          error_axios: ""
        }
        case DELETE_LICZNIK:
        return {
          ...state,
          loading_sm: false,
          loading: false,
          success_axios: action.payload,
          error_axios: ""
        }
      case ADD_LOKATOR:
        return {
          ...state,
          loading: false,
          success_axios: action.payload,
          error_axios: ""
        }
        case DELETE_LOKATOR:
          return {
            ...state,
            loading: false,
            success_axios: action.payload,
            error_axios: ""
          }
        case EDIT_LOKATOR:
          return {
            ...state,
            loading: false,
            success_axios: action.payload,
            error_axios: ""
          }
        case GET_LOKATORZY:
          return {
            ...state,
            lokatorzy: action.payload.data[0],
            light_nieruchomosci: action.payload.data[1],
            loading: false,
          }
          case GET_LICZNIKI:
            return {
              ...state,
              liczniki: action.payload.data,
              loading_sm: false,
            }
        case GET_LOKATOR:
        return {
          ...state,
          lokator: action.payload.data,
          loading: false,
          success_axios: "",
          error_axios: ""
        }
      case ADD_NIERUCHOMOSC:
        return {
          ...state,
          loading: false,
          success_axios: action.payload,
          error_axios: ""
        }
        case DELETE_NIERUCHOMOSC:
          return {
            ...state,
            loading: false,
            success_axios: action.payload,
            error_axios: ""
          }
        case EDIT_NIERUCHOMOSC:
          return {
            ...state,
            loading: false,
            success_axios: action.payload,
            error_axios: ""
          }
        case GET_NIERUCHOMOSCI:
          return {
            ...state,
            nieruchomosci: action.payload.data,
            light_nieruchomosci: action.payload.data.map(row => ({id: row.id, nazwa: row.nazwa })),
            loading: false,
          }
        case GET_NIERUCHOMOSC:
        return {
          ...state,
          nieruchomosc: action.payload.data,
          loading: false,
          success_axios: "",
          error_axios: ""
        }
        case CLEAR_RESPONSE:
          return {
            ...state,
             error_axios: "",
             success_axios: "",
          }
        case APP_LOADING:
          return {
            ...state,
            loading: action.payload,
          }
        case AUTH_APP:
            return {
            ...state,
            isAuthenticated: true,
            loading: false,
            error_axios: ""
            };
        case AUTH_APP_LOGOUT:
            return {
            ...state,
            isAuthenticated: false,
            loading: false,
            error_axios: ""
            };
        case ERROR_AXIOS:
            return {
            ...state,
            error_axios: action.payload,
            loading: false,
            loading_sm: false,
            loading_od: false,
            success_axios: "",
            };
        default:
            return state;
    }
  }